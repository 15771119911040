import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import { CircularProgress, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { MenuItem, Select, Card } from "@material-ui/core";

/* eslint-disable */
function Add(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [auth1, setAuth1] = useState("");
  const [auth2, setAuth2] = useState("");
  const [auth3, setAuth3] = useState("");
  const [type, setType] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { openModal, closeModal } = props;

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);

  const handleSave = async () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailSenders`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      ema_type: type,
      ema_name: name,
      ema_description: description,
      ema_apiURL: "",
      ema_auth1: auth1,
      ema_auth2: auth2,
      ema_auth3: auth3,
      ema_status: 0,
    });
    console.log(options.data);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        props.onDone();
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }} 
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Add a sender
        </MDTypography>
      </MDBox>

      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
              <MDBox mb={2} mt={2} ml={2} mr={2}>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <Select
                    disabled={isLoading}
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  >
                    <MenuItem key={0} value={0}>
                      Amazon SES
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      SMTP.com
                    </MenuItem>
                  </Select>
                </MDBox>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Friendly name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </MDBox>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Description"
                    multiline
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </MDBox>
                {type === 0 && (
                  <>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <TextField
                        type="text"
                        label="Access"
                        fullWidth
                        value={auth1}
                        onChange={(e) => setAuth1(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <TextField
                        type="text"
                        label="Secret"
                        fullWidth
                        value={auth2}
                        onChange={(e) => setAuth2(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <TextField
                        type="text"
                        label="Region"
                        fullWidth
                        value={auth3}
                        onChange={(e) => setAuth3(e.target.value)}
                      />
                    </MDBox>
                  </>
                )}
                {type === 1 && (
                  <>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <TextField
                        type="text"
                        label="API Key"
                        fullWidth
                        value={auth1}
                        onChange={(e) => setAuth1(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <TextField
                        type="text"
                        label="Channel"
                        fullWidth
                        value={auth2}
                        onChange={(e) => setAuth2(e.target.value)}
                      />
                    </MDBox>
                  </>
                )}
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton onClick={closeModal} disabled={isLoading} variant="outlined" color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <MDBox display="flex">
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            </MDBox>
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Add;
