import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid, Select, Tooltip } from "@mui/material";
import client from "ApiClient";
import MDTypography from "components/MDTypography";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Card, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import moment from "moment";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import PieChart from "examples/Charts/PieChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

/* eslint-disable */
export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [broadcasts, setBroadcasts] = useState([]);
  const [userStats, setUserStats] = useState(null);
  const [subscriptionStats, setSubscriptionStats] = useState(null);
  const [percentage, setPercentage] = useState("true");
  const [dateFrom, setDateFrom] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));

  // GRAPH DATA
  const [sentByDate, setSentByDate] = useState({});
  const [opensByDate, setOpensByDate] = useState({});
  const [clicksByDate, setClicksByDate] = useState({});
  const [sentBySender, setSentBySender] = useState({});
  const options = {
    method: "GET",
    url: "reports",
  };

  const getBroadcasts = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `broadcasts/getAll`;
    options.params = {
      currentPage: page,
      perPage: rowsPerPage,
      search: searchText,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setBroadcasts(response.all_broadcasts);
        setCount(response.total);
        setPage(response.current_page);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getDashboardStats = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `broadcasts/getDashboardStats`;

    options.params = {
      from: moment(dateFrom).format("YYYY-MM-DD"),
      to: moment(dateTo).format("YYYY-MM-DD"),
    };

    await client
      .request(options)
      .then((response) => {
        setSentByDate(response.sent_by_date);
        setOpensByDate(response.open_by_date);
        setClicksByDate(response.click_by_date);
        setSentBySender(response.sent_by_sender);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getUserStats = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `reports/UserStats`;

    await client
      .request(options)
      .then((response) => {
        setUserStats(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSubscriptionsStats = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `reports/SubscriptionStats`;

    await client
      .request(options)
      .then((response) => {
        setSubscriptionStats(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("broadcast_percentage") == null)
      window.sessionStorage.setItem("broadcast_percentage", percentage);
    else setPercentage(window.sessionStorage.getItem("broadcast_percentage"));
    getBroadcasts();
    getUserStats();
    getSubscriptionsStats();
    getDashboardStats();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getBroadcasts();
      getUserStats();
      getSubscriptionsStats();
      getDashboardStats();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getBroadcasts();
  }, [rowsPerPage, page, searchText]);

  useEffect(() => {
    getDashboardStats();
  }, [dateFrom, dateTo]);

  const getDateValue = (date) => {
    return moment(date).format("L");
  };

  const getSegmentSelected = (seg) => {
    const segmentProps = seg;

    const statuses =
      segmentProps.statuses.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Status:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.statuses
              .filter((element) => element.selected)
              .map((s) => s.status)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const educations =
      segmentProps.educations.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Education:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.educations
              .filter((element) => element.selected)
              .map((s) => s.education)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const children =
      segmentProps.children.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Children:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.children
              .filter((element) => element.selected)
              .map((s) => s.children)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const ethnicities =
      segmentProps.ethnicities.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Ethnicities:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ethnicities
              .filter((element) => element.selected)
              .map((s) => s.ethnicity)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const genders =
      segmentProps.genders.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Genders:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.genders
              .filter((element) => element.selected)
              .map((s) => s.gender)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const age =
      segmentProps.ageRanges.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Age ranges:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ageRanges
              .filter((element) => element.selected)
              .map((s) => s.age_range)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const employments =
      segmentProps.employments.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Employments:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.employments
              .filter((element) => element.selected)
              .map((s) => s.employment)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsEngaged =
      segmentProps.broadcastsEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsNoEngaged =
      segmentProps.broadcastsNoEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts no engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsNoEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    if (segmentProps.tags == null) segmentProps.tags = [];
    const tags =
      segmentProps.tags.length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Tags:{" "}
          </MDTypography>
          <MDTypography variant="body">{segmentProps.tags.join(", ")}</MDTypography>
        </div>
      ) : (
        ""
      );
    if (
      statuses == "" &&
      educations == "" &&
      children == "" &&
      employments == "" &&
      ethnicities == "" &&
      genders == "" &&
      age == "" &&
      broadcastsEngaged == "" &&
      broadcastsNoEngaged == "" &&
      tags == ""
    )
      return (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            None
          </MDTypography>
        </div>
      );
    else
      return (
        <div>
          {statuses}
          {educations}
          {employments}
          {children}
          {ethnicities}
          {genders}
          {age}
          {broadcastsEngaged}
          {broadcastsNoEngaged}
          {tags}
        </div>
      );
  };

  const changePercentageHandler = (event) => {
    window.sessionStorage.setItem("broadcast_percentage", event.target.value);
    setPercentage(event.target.value);
  };

  const columns = [
    {
      label: "User email",
      name: "bro_user_email",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Broadcast name",
      name: "bro_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Email sender",
      name: "ema_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Recipients",
      name: "total_recipients",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Sent",
      name: "bro_totalSent",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: false,
        sort: false,
      },
    },
    {
      label: "Sent %",
      name: "bro_totalSentPerc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: false,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Opens %",
      name: "total_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Clicks %",
      name: "total_clicks_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubscribes",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Unsubscribes %",
      name: "total_unsubs_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
      },
    },
    {
      label: "Complaints %",
      name: "total_complaints_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Sales",
      name: "total_sales",
      options: {
        display: false,
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => "$ " + Math.round(value),
      },
    },
    {
      label: "Segmentation",
      name: "bro_segments",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        sort: false,
        customBodyRender: (value) => getSegmentSelected(value),
      },
    },
    {
      label: "Scheduled date",
      name: "bro_date",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value) => getDateValue(value),
      },
    },
  ];

  const CustomToolbar = ({ displayData }) => {
    return (
      <Tooltip title="Change the stats view between counts and percentage" placement="top">
        <Select
          style={{ marginLeft: 15 }}
          value={percentage}
          label="Change the view of opens and clicks"
          onChange={(event) => changePercentageHandler(event)}
        >
          <MenuItem value="false">#</MenuItem>
          <MenuItem value="true">%</MenuItem>
        </Select>
      </Tooltip>
    );
  };

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    serverSide: true,
    count: count,
    page: page,
    searchText: searchText,
    customToolbar: CustomToolbar,
    onTableChange: (action, tableState) => {
      // console.log("action", action);
      // console.log("tableState", tableState);
      setRowsPerPage(tableState.rowsPerPage);

      if (action === "changePage") {
        //  console.log("Go to page", tableState.page);
        setPage(tableState.page);
        // this.changePage(page);
      }
      if (action === "changeRowsPerPage") {
        //  console.log("Change rows per page", rowsPerPage);
        setRowsPerPage(tableState.rowsPerPage);
        // this.changeRowsPerPage(rowsPerPage);
      }
      if (action === "search") {
        //  console.log("Change rows per page", rowsPerPage);
        setSearchText(tableState.searchText);
        // this.changeRowsPerPage(rowsPerPage);
      }
    },
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ padding: 15, borderRadius: 10 }}>
            <MDTypography variant="h2">
              Dashboard
              {isLoading ? (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              ) : (
                <MDBox display="flex" justifyContent="left">
                  <MDBox mt={1}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        required
                        renderInput={(props) => <TextField {...props} />}
                        label="Select the date from"
                        value={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </MDBox>
                  <MDBox ml={5} mt={1}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        required
                        renderInput={(props) => <TextField {...props} />}
                        label="Select the date to"
                        value={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </MDBox>
                </MDBox>
              )}
            </MDTypography>
          </Card>
        </Grid>
        {userStats && (
          <>
            <Grid item xs={12} md={3} lg={3} p={1}>
              <ComplexStatisticsCard
                color="primary"
                icon="subscribers"
                title="Total active users in the last 30 days"
                count={userStats.total_logins_last_30 == null ? 0 : userStats.total_logins_last_30}
                percentage={{
                  color:
                    userStats.total_logins_last_30 - userStats.total_logins_last_60 < 0
                      ? "error"
                      : "success",
                  amount:
                    userStats.total_logins_last_30 == null
                      ? 0
                      : Math.abs(userStats.total_logins_last_30 - userStats.total_logins_last_60),
                  label:
                    userStats.total_logins_last_30 - userStats.total_logins_last_60 < 0
                      ? " less than last month"
                      : " more than last month",
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3} p={1}>
              <ComplexStatisticsCard
                color="primary"
                icon="subscribers"
                title="Total new subscriptions in the last 30 days"
                count={
                  userStats.total_new_subscriptions_30 == null
                    ? 0
                    : userStats.total_new_subscriptions_30
                }
                percentage={{
                  color:
                    userStats.total_new_subscriptions_30 - userStats.total_new_subscriptions_60 < 0
                      ? "error"
                      : "success",
                  amount:
                    userStats.total_new_subscriptions_30 == null
                      ? 0
                      : Math.abs(
                          userStats.total_new_subscriptions_30 -
                            userStats.total_new_subscriptions_60
                        ),
                  label:
                    userStats.total_new_subscriptions_30 - userStats.total_new_subscriptions_60 < 0
                      ? " less than last month"
                      : " more than last month",
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3} p={1}>
              <ComplexStatisticsCard
                color="primary"
                icon="subscribers"
                title="Total renewed subscriptions in the last 30 days"
                count={
                  userStats.total_ren_subscriptions_30 == null
                    ? 0
                    : userStats.total_ren_subscriptions_30
                }
                percentage={{
                  color:
                    userStats.total_ren_subscriptions_30 - userStats.total_ren_subscriptions_60 < 0
                      ? "error"
                      : "success",
                  amount:
                    userStats.total_ren_subscriptions_30 == null
                      ? 0
                      : Math.abs(
                          userStats.total_ren_subscriptions_30 -
                            userStats.total_ren_subscriptions_60
                        ),
                  label:
                    userStats.total_ren_subscriptions_30 - userStats.total_ren_subscriptions_60 < 0
                      ? " less than last month"
                      : " more than last month",
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3} p={1}>
              <ComplexStatisticsCard
                color="primary"
                icon="subscribers"
                title="Total deleted subscriptions in the last 30 days"
                count={
                  userStats.total_del_subscriptions_30 == null
                    ? 0
                    : userStats.total_del_subscriptions_30
                }
                percentage={{
                  color:
                    userStats.total_del_subscriptions_30 - userStats.total_del_subscriptions_60 < 0
                      ? "error"
                      : "success",
                  amount:
                    userStats.total_del_subscriptions_30 == null
                      ? 0
                      : Math.abs(
                          userStats.total_del_subscriptions_30 -
                            userStats.total_del_subscriptions_60
                        ),
                  label:
                    userStats.total_del_subscriptions_30 - userStats.total_del_subscriptions_60 < 0
                      ? " less than last month"
                      : " more than last month",
                }}
              />
            </Grid>
          </>
        )}
        {subscriptionStats && (
          <>
            <Grid item xs={12} md={6} lg={6} p={1}>
              <PieChart
                title="Subscriptions by frecuency"
                description="Total subscriptions count by frecuency"
                chart={subscriptionStats.subscriptions_by_frequency}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} p={1}>
              <PieChart
                title="Subscriptions by status"
                description="Total subscriptions count by status"
                chart={subscriptionStats.subscriptions_by_status}
              />
            </Grid>
          </>
        )}
        {false && (
          <Grid item xs={12} md={6} lg={6} p={1}>
            <PieChart
              title="Subscriptions by price"
              description="Total subscriptions count by price"
              chart={subscriptionStats.subscriptions_by_price}
            />
          </Grid>
        )}
        {opensByDate && (
          <Grid item xs={12} md={6} lg={6} p={1}>
            <DefaultLineChart
              title="Opens by date"
              description="Total opens count by date"
              chart={opensByDate}
            />
          </Grid>
        )}
        {clicksByDate && (
          <Grid item xs={12} md={6} lg={6} p={1}>
            <DefaultLineChart
              title="Clicks by date"
              description="Total clicks count by date"
              chart={clicksByDate}
            />
          </Grid>
        )}
        {sentByDate && (
          <Grid item xs={12} md={6} lg={6} p={1}>
            <DefaultLineChart
              title="Sent by date"
              description="Total sent count by date"
              chart={sentByDate}
            />
          </Grid>
        )}
        {sentBySender && (
          <Grid item xs={12} md={6} lg={6} p={1}>
            <PieChart
              title="Sent by sender"
              chart={sentBySender}
              description="Sent by SMTP sender"
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={12}>
          <Card style={{ padding: 15, borderRadius: 10 }}>
            <MDTypography variant="h2">
              Broadcasts by users
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MUIDataTable data={broadcasts} columns={columns} options={tableOptions} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
