import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { FormControlLabel, ListItemText, MenuItem, Select, Switch } from "@material-ui/core";

/* eslint-disable */
function Edit(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [promoSubscriptions, setPromoSubscriptions] = useState([]);
  const [promoSubscriptionsOriginal, setPromoSubscriptionsOriginal] = useState([]);
  const [selectedPromoPrice, setSelectedPromoPrice] = useState(null);
  const [freeDays, setFreeDays] = useState("");
  const { openModal, closeModal } = props;

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);
  
  const options = {
    method: "POST",
    url: `coupons`,
    headers: {
      "content-type": "application/json",
    },
  };

  const clearVariables = () => {
    setCode("");
    setTitle("");
    setDescription("");
    setFreeDays(100);
    setSelectedPrice(subscriptions[0]);
    setSelectedPromoPrice(promoSubscriptions[0]);
    setShowPrices(false);
    props.closeModal();
  };

  const getStripeSubscriptions = async () => {
    options.method = "GET";
    options.url = `users/getStripeSubscriptions`;
    await client
      .request(options)
      .then((response) => {
        let arrPricing = [];
        const arrProducts = Array.from(new Set(response.map((item) => item.product_id)));
        arrProducts
          .map((item) => response.filter((item2) => item2.product_id == item))
          .map((item) =>
            arrPricing.push({
              title: item[0].product_name,
              productId: item[0].product_id,
              annual: item[0].annual == 0 ? item[1].annual : item[0].annual,
              monthly: item[0].monthly == 0 ? item[1].monthly : item[0].monthly,
              contacts: item[0].contacts,
            })
          );

        setSubscriptions(arrPricing);

        subscriptions.filter((item) => item.productId == props.coupon.cou_originalProductId)[0];
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStripePromoSubscriptions = async () => {
    options.method = "GET";
    options.url = `users/getStripePromoSubscriptions`;
    await client
      .request(options)
      .then((response) => {
        let arrPricing = [];
        const arrProducts = Array.from(new Set(response.map((item) => item.product_id)));

        arrProducts
          .map((item) => response.filter((item2) => item2.product_id == item))
          .map((item) =>
            arrPricing.push({
              title: item[0].product_name,
              productId: item[0].product_id,
              annual: item[0].annual == 0 ? item[1].annual : item[0].annual,
              monthly: item[0].monthly == 0 ? item[1].monthly : item[0].monthly,
              contacts: item[0].contacts,
            })
          );

        setPromoSubscriptionsOriginal(arrPricing);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.coupon != undefined) {
      setCode(props.coupon.cou_code);
      setTitle(props.coupon.cou_title);
      setDescription(props.coupon.cou_description);
      setFreeDays(props.coupon.cou_freeDays);
      setShowPrices(props.coupon.cou_discountedProductId != "");
      if (props.coupon.cou_originalProductId != "" && subscriptions.length > 0)        
      setSelectedPrice(
        subscriptions.filter((item) => item.productId == props.coupon.cou_originalProductId)[0]
      );
    }
  }, [props.coupon]);

  useEffect(() => {
    getStripePromoSubscriptions();
    getStripeSubscriptions();
  }, []);

  useEffect(() => {
    if (promoSubscriptionsOriginal.length > 0 && selectedPrice != null) {
      const promoSubscriptions = promoSubscriptionsOriginal.filter(
        (item) => item.contacts == selectedPrice.contacts
      );
      setPromoSubscriptions(promoSubscriptions);
      if (promoSubscriptions.length > 0) {
        setSelectedPromoPrice(
          promoSubscriptionsOriginal.filter((item) => item.contacts == selectedPrice.contacts)[0]
        );
      } else setSelectedPromoPrice(null);
    }
  }, [selectedPrice]);

  const handleSave = async () => {
    setIsLoading(true);
    const options = {
      method: "PUT",
      url: `coupons`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      cou_id: props.coupon.cou_id,
      cou_code: code,
      cou_title: title,
      cou_description: description,
      cou_freeDays: freeDays,
      cou_status: props.coupon.cou_status,
      cou_originalProductId: showPrices ? selectedPrice.productId : "",
      cou_discountedProductId: showPrices ? selectedPromoPrice.productId : "",
    });

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        clearVariables();
        props.onDone();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }} 
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Edit a coupon
        </MDTypography>
      </MDBox>

      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
              <MDBox mb={2} mt={2} ml={2} mr={2}>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Coupon title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </MDBox>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Coupon description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </MDBox>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Coupon code"
                    fullWidth
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </MDBox>
                <MDBox pb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="number"
                    label="Free days"
                    value={freeDays}
                    onChange={(e) => setFreeDays(e.target.value)}
                  />
                </MDBox>
                <FormControlLabel
                  style={{ marginLeft: 10, marginBottom: 10 }}
                  value="end"
                  labelPlacement="end"
                  control={
                    <Switch
                      checked={showPrices}
                      onChange={() => {
                        setShowPrices(!showPrices);
                      }}
                    />
                  }
                  label={<MDTypography variant="subtitle1">Set promo price</MDTypography>}
                />

                {showPrices && (
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <FormControlLabel
                      style={{ marginLeft: 10, marginBottom: 10 }}
                      label={<MDTypography variant="subtitle1">Select the plan </MDTypography>}
                      labelPlacement="top"
                      control={
                        <Select
                          placeholder="Short codes"
                          value={selectedPrice}
                          onChange={(e) => setSelectedPrice(e.target.value)}
                        >
                          {subscriptions.map((item) => (
                            <MenuItem key={item.productId} value={item}>
                              <ListItemText
                                primary={item.title}
                                secondary={
                                  " $" +
                                  item.monthly / 100 +
                                  " monthly" +
                                  " $" +
                                  item.annual / 100 +
                                  " annual"
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                    {promoSubscriptions.length > 0 ? (
                      <FormControlLabel
                        style={{ marginLeft: 50, marginBottom: 10 }}
                        label={
                          <MDTypography variant="subtitle1">Select the promo plan</MDTypography>
                        }
                        labelPlacement="top"
                        control={
                          <Select
                            style={{ marginLeft: 50 }}
                            value={selectedPromoPrice}
                            onChange={(e) => setSelectedPromoPrice(e.target.value)}
                          >
                            {promoSubscriptions.map((item) => (
                              <MenuItem key={item.productId} value={item}>
                                <ListItemText
                                  primary={item.title}
                                  secondary={
                                    " $" +
                                    item.monthly / 100 +
                                    " monthly" +
                                    " $" +
                                    item.annual / 100 +
                                    " annual"
                                  }
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    ) : (
                      <MDBox mt={5} ml={10}>
                        {" "}
                        <MDTypography variant="subtitle1">
                          No promos availables for this plan
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <MDBox display="flex">
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            </MDBox>
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Edit;
