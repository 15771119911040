import client from "ApiClient";
import React from "react";

export const FeatureFlags = React.createContext({});

/*eslint-disable*/
export function FeatureFlagsProvider({ children }) {
  const [features, setFeatures] = React.useState({
    isGooglePayEnabled: false,
    plName: "sendpad", //sendpad 
    hiddenMenus: ["catalogs", "series", "blogs", "automation", "sales"], 
    colorBackground: "#F4F0F7",
    colorPrimary: "#735AC7",
    colorPrimaryFocus: "#735AC7",
    colorPrimaryGradient: "#735AC7",
    colorPrimaryGradientState: "#998FC7",
  });
  
  /*SENDPAD*/
  switch (process.env.REACT_APP_PLNAME) {
    case "sendpad":
      features.plName = "sendpad";
      features.hiddenMenus = ["catalogs", "series", "blogs", "automation", "sales"];
      features.colorBackground = "#F4F0F7";
      features.colorPrimary = "#735AC7";
      features.colorPrimaryFocus = "#735AC7";
      features.colorPrimaryGradient = "#735AC7";
      features.colorPrimaryGradientState = "#998FC7";
      break;
    case "mailsense":
      features.plName = "mailsense";
      features.hiddenMenus = [];
      features.colorBackground = "#F2F2F3";
      features.colorPrimary = "#2F8CFF";
      features.colorPrimaryFocus = "#2F8CFF";
      features.colorPrimaryGradient = "#2F8CFF";
      features.colorPrimaryGradientState = "#2EA3F2";
      break;
  }

  const getFeatures = () => {
    const options = {
      method: "GET",
      url: "users/getFeatures",
    };
    client.request(options).then((response) => {
      if (response != undefined) setFeatures(response);
    });
  };

  /*
  React.useEffect(() => {
    getFeatures;
  }, []);
  */

  return <FeatureFlags.Provider value={{ features }}>{children}</FeatureFlags.Provider>;
}

export const useFeatureFlags = () => React.useContext(FeatureFlags);
