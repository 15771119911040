import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "./table";

/* eslint-disable */
export default function Senders(props) {
  const [addGroup, setAddGroup] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [currentEmailCopy, setCurrentEmailCopy] = useState();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddGroup = () => {
    const refresh = addGroup + 1;
    setAddGroup(refresh);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Table onAdd={handleOpenModal} itemAdded={addGroup} />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
